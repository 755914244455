import { ReactElement, useEffect } from "react"
import { Paper, Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router";
import useAxios from "axios-hooks";
import { CustomerProfile } from '../../../app/components/customer/profile/customer-profile.types';
import { HttpError } from "tools/HttpTypes";
import ProfilePicture from '../../../style/icons/icon-profile.svg';
import { NxButtonLink, NxButtonVariant } from "@nextbank/ui-components";
import commandAccessService from "command/commandAccessService";

const CustomerDetails = (): ReactElement => {
    const url = useLocation();
    const showCustomerDetails = url.pathname.includes('cocree');
    const { customerId } = useParams<{ customerId: string }>();
    const isExposurePage = url.pathname.includes('exposure');
    const [{ data: customerData, loading }, fetchCustomerData] = useAxios<CustomerProfile, HttpError>(
        `/customers/${customerId}`, {
            manual: true
        }
    );
    const hasCreateExposureAccess = commandAccessService.canExecute("CreateExposure");

    useEffect(() => {
        if (showCustomerDetails) {
            fetchCustomerData()
        }
    }, [])

    return (
        <>
            {showCustomerDetails && !loading ? <>
                <Paper elevation={1}>
                    <Box p={3} mb={2} pl={1.5}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10rem",
                        }}>
                            <ProfilePicture />
                            <div>
                                <span style={{
                                    color: "gray"
                                }}>Name:</span>
                                <div>
                                    {customerData?.individualData ? `${customerData?.individualData?.lastName}, ${customerData?.individualData?.firstName} ${customerData?.individualData?.middleName}` : customerData?.corporateData?.businessName}
                                </div>
                            </div>

                            <div>
                                <span style={{
                                    color: "gray"
                                }}>CIF:</span>
                                <div>
                                    {customerData?.customerNumber}
                                </div>
                            </div>

                            {isExposurePage && hasCreateExposureAccess ? <div style={{
                                marginLeft: "auto"
                            }}>
                                <NxButtonLink disabled={url.pathname.includes('exposure/new')} variant={NxButtonVariant.CONTAINED} to={`/customer/${customerId}/cocree/exposure/new`}>Add new exposure</NxButtonLink>
                            </div> : null}
                        </div>
                    </Box>
                </Paper>
            </> : null}
        </>
    )
}

export default CustomerDetails;