import CollateralList from 'customer/collateral/CollateralList';
import CreateCollateral from 'customer/collateral/CreateCollateral';
import UpdateCollateral from 'customer/collateral/UpdateCollateral';
import Cocree from 'customer/cocree/Cocree';
import Subject from 'customer/cocree/Subject/Subject';
import Exposure from 'customer/cocree/Exposure/Exposure';
import ExposureDetails from 'customer/cocree/Exposure/ExposureDetails';
import NegativeInformation from 'customer/cocree/NegativeInformation';
import RouteDefinition from "routes/RouteDefinition";
import { CustomerProfile } from "components/customer/profile/customer-profile.types";
import axios from "axios";
import { ReactElement } from 'react';

async function getCustomerData(customerId: string): Promise<CustomerProfile> {
  const { data: customer } = await axios.get<CustomerProfile>(`/customers/${customerId}`);
  return customer;
}

const routes: RouteDefinition[] = [
  {
    name: async ({ customerId }: { customerId: string }): Promise<string> => {
      const customer = await getCustomerData(customerId);
      return customer.effectiveName ?? '??';
    },
    path: '/customer/:customerId'
  },
  {
    name: 'Collaterals',
    path: '/customer/:customerId/collaterals',
    Component: CollateralList
  },
  {
    name: 'Add collateral',
    path: '/customer/:customerId/collaterals/add',
    Component: CreateCollateral,
    commandAccess: ['CreateCollateral']
  },
  {
    name: 'Update collateral',
    path: '/customer/:customerId/collaterals/:collateralId',
    Component: UpdateCollateral,
    commandAccess: ['UpdateCollateral']
  },
  {
    name: 'Cocree',
    path: '/customer/:customerId/cocree',
    Component: Cocree,
  },
  {
    name: 'Subject',
    path: '/customer/:customerId/cocree/subject',
    Component: function SubjectCustomer(): ReactElement {
      return (
        <Subject getCustomerData={getCustomerData}/>
      )
    },
  },
  {
    name: 'Exposure',
    path: '/customer/:customerId/cocree/exposure',
    Component: Exposure,
  },
  {
    name: 'Add New Exposure',
    path: '/customer/:customerId/cocree/exposure/new',
    Component: ExposureDetails,
  },
  {
    name: 'Exposure Details',
    path: '/customer/:customerId/cocree/exposure/:exposureId',
    Component: ExposureDetails,
  },
  {
    name: 'Negative Information',
    path: '/customer/:customerId/cocree/negative-information',
    Component: NegativeInformation,
  },
];

export default routes;