import { ReactElement } from "react"
import {
    NxButton,
    NxButtonLink,
    NxButtonVariant,
    NxLoader,
    NxTable,
    NxTableColumn
} from '@nextbank/ui-components';
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import { HttpError } from "tools/HttpTypes";
import commandAccessService from 'command/commandAccessService';

const columns: NxTableColumn<any>[] = [
  {
    title: "No.",
    field: "index",
  },
  {
    title: "Contract no.",
      field: "providerContractNumber",
  },
  {
    title: "Record Type",
    field: "recordType",
  },
  {
    title: "Exposure status",
    field: "exposureStatus",
  },
  {
    title: "Outstanding balance",
    field: "outstandingBalance",
  },
];

const Exposure = (): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const [{ data: exposure, loading: exposureLoading }, fetchExposures] = useAxios<any, HttpError>(
        `/cocree/customers/${customerId}/exposures`
    );
    const [{ data: deletedExposure, loading: isDeleteExposureLoading }, deleteExposure] = useAxios<any>(
      "/command/DeleteExposure",
      {
        manual: true,
      }
    );
    const hasDeleteAccess = commandAccessService.canExecute("DeleteExposure");
    if (exposure) {
      exposure.forEach((obj: any, i: number) => {
        obj.index = i + 1;
        if (obj.recordType === 'OTHER_CREDIT_ACCOMMODATION') {
          obj.recordType = 'Other credit accomodation'
        }
        if (obj.recordType === 'DEBT_AND_EQUITY_INVESTMENT') {
          obj.recordType = "Debt & equity investment"
        }
        if (obj.exposureStatus === 'ACTIVE') {
          obj.exposureStatus = 'Active'
        }
        if (obj.exposureStatus === 'CLOSED_IN_ADVANCE') {
          obj.exposureStatus = 'Closed in advance'
        }
      });
    }
    const OpenExposure = ({ data }: { data: any }): ReactElement => {
        return <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`/customer/${customerId}/cocree/exposure/${data.id}`}>Open</NxButtonLink>;
    }
      
  const DeleteExposure = ({ data }: { data: any }): ReactElement => {
    return (data.reported || !hasDeleteAccess ? <></> : <NxButton variant={NxButtonVariant.DELETE} onClick={async () => {
      try {
        await deleteExposure({
          method: 'POST',
          data: {
            id: data.id
          }
        })

        await fetchExposures()
      } catch(error) {
        console.log(error)
      }
    }}>Delete</NxButton>)
  }
        
    return (
        <>
        {!exposureLoading && !isDeleteExposureLoading ? <div>
                Exposure

                <NxTable columns={columns}
                    rowActions={[OpenExposure, DeleteExposure]}
                    data={exposure} />
            </div> : <NxLoader/>}
        </>
    )
}

export default Exposure;