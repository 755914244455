import { ReactElement, useState } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
    NxFormikDatePicker,
    NxFormikNumberInput,
    NxFormikPercentageInput,
    NxFormikInput,
    NxLoader
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
interface DebtEquityInvestment {
    providerContractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    amountGranted: number;
    outstandingBalance: number;
    estimatedCreditLossesAllowance: number;
    nominalInterestRate: number;
    probabilityOfDefault: number;
    effectiveInterestRate: number;
    unearnedInterest: number;
    interestSetting: string;
    daysPastDue: number;
    restructureCreditType?: string;
    guaranteeType?: string;
    exposureStatus: string;
    investmentType: string;
    accumulatedMarketGains: number;
    creditRatingAgencyUsed: string;
    creditRating: string;
    creditRatingLastIssuer: string;
    natureOfOffering: string;
    repricingFrequency?: string;
}

const DebtEquityInvestmentSchema = Yup.object().shape({
    providerContractNumber: Yup.string().required('Provider contract number field is required'),
    investmentType: Yup.string().required('Investment type field is required'),
    contractStartDate: Yup.date()
        .required('Contract start date is required')
        .typeError('Invalid date format')
        .max(Yup.ref('contractEndDate'), 'Must not be greater than Contract end date'),
    contractEndDate: Yup.date()
        .required('Contract end date is required')
        .typeError('Invalid date format')
        .min(Yup.ref('contractStartDate'), 'Must not be lesser than Contract start date'),
    amountGranted: Yup.number().required('Amount granted field is required').min(0, "Value should be a positive number"),
    accumulatedMarketGains: Yup.number().required('Accumulated market gains field is required').min(0, "Value should be a positive number"),
    outstandingBalance: Yup.number().required('Outstanding balance field is required').min(0, "Value should be a positive number"),
    estimatedCreditLossesAllowance: Yup.number().required('Estimated credit losses allowance field is required').min(0, "Value should be a positive number"),
    nominalInterestRate: Yup.number().required('Nominal interest rate field is required').min(0, "Value should be a positive number"),
    probabilityOfDefault: Yup.number().required('Probability of default field is required').min(0, "Value should be a positive number"),
    effectiveInterestRate: Yup.number().required('Effective interest rate field is required').min(0, "Value should be a positive number"),
    unearnedInterest: Yup.number().required('Unearned interest field is required').min(0, "Value should be a positive number"),
    interestSetting: Yup.string().required('Interest setting field is required'),
    repricingFrequency: Yup.string().when('interestSetting', {
        is: 'REPRICEABLE',
        then: Yup.string().required('Repricing frequency is required'),
        otherwise: Yup.string().notRequired()
    }),
    daysPastDue: Yup.number().required('Days past due field is required').min(0, "Value should be a positive number").integer(),
    restructureCreditType: Yup.string().notRequired(),
    guaranteeType: Yup.string().notRequired(),
    creditRatingAgencyUsed: Yup.string().required('Credit rating agency used field is required'),
    creditRatingLastIssuer: Yup.string().required('Credit rating last issuer field is required'),
    creditRating: Yup.string().required('Credit rating field is required'),
    natureOfOffering: Yup.string().required('Nature of offering field is required'),
    exposureStatus: Yup.string().required('Exposure status field is required'),
});

interface ExposureDEIFormProps {
    hasAccess: boolean;
    exposureData: any;
    customerId: number;
    userBranchId: number;
    dictionaryEnums: any;
}

const ExposureDEIForm = ({ hasAccess, exposureData, customerId, userBranchId, dictionaryEnums }: ExposureDEIFormProps): ReactElement => {
    const [{ data: DEIForm, loading: isDEIFormLoading }, createDEIForm] = useAxios<DebtEquityInvestment>(
        exposureData?.id ? "/command/UpdateExposure" : "/command/CreateExposure",
        {
            manual: true,
        }
    );
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();

    const formInitValues = {
        providerContractNumber: exposureData?.providerContractNumber ?? '',
        contractStartDate: exposureData?.contractStartDate ?? '',
        contractEndDate: exposureData?.contractEndDate ?? '',
        amountGranted: exposureData?.amountGranted ?? null,
        outstandingBalance: exposureData?.outstandingBalance ?? null,
        estimatedCreditLossesAllowance: exposureData?.estimatedCreditLossesAllowance ?? null,
        nominalInterestRate: exposureData?.nominalInterestRate ?? null,
        probabilityOfDefault: exposureData?.probabilityOfDefault ?? null,
        effectiveInterestRate: exposureData?.effectiveInterestRate ?? null,
        unearnedInterest: exposureData?.unearnedInterest ?? null,
        interestSetting: exposureData?.interestSetting ?? '',
        daysPastDue: exposureData?.daysPastDue ?? null,
        restructureCreditType: exposureData?.restructureCreditType ?? '',
        guaranteeType: exposureData?.guaranteeType ?? '',
        exposureStatus: exposureData?.exposureStatus ?? '',
        investmentType: exposureData?.investmentType ?? '',
        accumulatedMarketGains: exposureData?.accumulatedMarketGains ?? null,
        creditRatingAgencyUsed: exposureData?.creditRatingAgencyUsed ?? '',
        creditRating: exposureData?.creditRating ?? '',
        creditRatingLastIssuer: exposureData?.creditRatingLastIssuer ?? '',
        natureOfOffering: exposureData?.natureOfOffering ?? '',
        repricingFrequency: exposureData?.repricingFrequency ?? '',
    }

    return (<>
        {isDEIFormLoading ? <NxLoader/> : (
            <NxFormik<DebtEquityInvestment>
                initialValues={formInitValues}
                validationSchema={DebtEquityInvestmentSchema}
                onSubmit={async (values): Promise<void> => {
                    try {
                        if (values.repricingFrequency === "") {
                            delete values.repricingFrequency
                        }
                        if (values.restructureCreditType === "") {
                            delete values.restructureCreditType
                        }
                        if (values.guaranteeType === "") {
                            delete values.guaranteeType
                        }
                        await createDEIForm({
                            method: 'POST',
                            data: {
                                id: exposureData.id ?? null,
                                ...values,
                                branchId: userBranchId,
                                customerId: customerId,
                                recordType: 'DEBT_AND_EQUITY_INVESTMENT',
                            }
                        })
                        history.goBack()
                    } catch (err) {
                        console.error(err)
                    }
                }}
            >
                {({ values,submitForm }): ReactElement => {
                    return (
                      <NxForm>
                        &nbsp;
                        <NxHeader>Debt & Equity Investment</NxHeader>
                        &nbsp;
                        <NxStack>
                          <NxFormikInput
                            disabled={exposureData?.reported || !hasAccess}
                            maxLength={38}
                            name="providerContractNumber"
                            label="Provider contract number"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="investmentType"
                            label="Interest setting"
                            options={Object.entries(dictionaryEnums.InvestmentType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikDatePicker
                            disabled={!hasAccess}
                            name="contractStartDate"
                            label="Contract start date"
                          />
                          <NxFormikDatePicker disabled={!hasAccess} name="contractEndDate" label="Contract end date" />
                          <NxFormikInput
                            disabled={!hasAccess}
                            maxLength={17}
                            name="amountGranted"
                            label="Amount granted"
                          />
                          <NxFormikInput
                            disabled={!hasAccess}
                            maxLength={17}
                            name="accumulatedMarketGains"
                            label="Accumulated market gains"
                          />
                          <NxFormikInput
                            disabled={!hasAccess}
                            maxLength={17}
                            name="outstandingBalance"
                            label="Outstanding balance"
                          />
                          <NxFormikInput
                            disabled={!hasAccess}
                            maxLength={17}
                            name="estimatedCreditLossesAllowance"
                            label="Allowance for estimated credit losses"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="nominalInterestRate"
                            label="Nominal interest rate"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="probabilityOfDefault"
                            label="Probability of default"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="effectiveInterestRate"
                            label="Effective interest rate"
                          />
                          <NxFormikNumberInput
                            disabled={!hasAccess}
                            max={17}
                            name="unearnedInterest"
                            label="Unearned interest"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="interestSetting"
                            label="Interest setting"
                            options={Object.entries(dictionaryEnums.InterestSetting).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          {values?.interestSetting === "REPRICEABLE" ? (
                            <NxFormikSelect
                              disabled={!hasAccess}
                              name="repricingFrequency"
                              label="Repricing Frequency"
                              options={Object.entries(dictionaryEnums.RepricingFrequency).map(([label, value]) => ({
                                label: label,
                                value: value,
                              }))}
                            />
                          ) : null}
                          <NxFormikNumberInput disabled={!hasAccess} max={4} name="daysPastDue" label="Days past due" />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="restructureCreditType"
                            label="Restructure credit type"
                            options={Object.entries(dictionaryEnums.RestructureCreditType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="guaranteeType"
                            label="Guarantee Type"
                            options={Object.entries(dictionaryEnums.GuaranteeType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRatingAgencyUsed"
                            label="Credit rating agency used"
                            options={Object.entries(dictionaryEnums.CreditAgency).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRating"
                            label="Credit rating issuer last investment"
                            options={Object.entries(dictionaryEnums.CreditRating).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditRatingLastIssuer"
                            label="Last credit rating issuer"
                            options={Object.entries(dictionaryEnums.CreditRatingIssuer).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="natureOfOffering"
                            label="Nature of offering"
                            options={Object.entries(dictionaryEnums.OfferingNature).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="exposureStatus"
                            label="Exposure status"
                            options={Object.entries(dictionaryEnums.ExposureStatus).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />

                          <NxRow>
                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                              Cancel
                            </NxButton>
                            {hasAccess ? (
                              <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                Save
                              </NxButton>
                            ) : null}
                          </NxRow>

                          <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  submitForm();
                                  setShowSavePopup(false);
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>

                          <NxPopup
                            header="Confirm"
                            open={showCancelPopup}
                            description="Discard changes and go to previous screen?"
                          >
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  setShowCancelPopup(false);
                                  history.goBack();
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>
                        </NxStack>
                      </NxForm>
                    );
                }}
            </NxFormik>
        )}
    </>)
}

export default ExposureDEIForm