import commandAccessService from 'command/commandAccessService';
import { ReactElement, useState } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxFormikCashInput,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
    NxFormikDatePicker
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";

interface CustomerCorporateProps {
    customerId: number;
    cocreeCustomer: any;
    dictionaryEnums: any;
}
interface CustomerData {
    customerId?: number;
    customerType?: string;
    businessType: string;
    termOfExistence?: string;
    grossAnnualIncome: number;
    netAnnualIncome: number;
    monthlyExpenses: number;
    largeExposureIndicator: boolean | null | undefined;
}

const CustomerCorporateSchema: Yup.SchemaOf<CustomerData> = Yup.object().shape({
    customerId: Yup.number().optional(),
    customerType: Yup.string().optional(),
    businessType: Yup.string().required("Business Type is a required field"),
    termOfExistence: Yup.string().optional(),
    grossAnnualIncome: Yup.number()
        .required("Gross Income is required")
        .positive("Gross Income must be greater than 0")
        .typeError("Gross Income must be greater than 0"),
    netAnnualIncome: Yup.number()
        .required("Net Income is required")
        .positive("Net Income must be greater than 0")
        .typeError("Net Income must be greater than 0"),
    monthlyExpenses: Yup.number()
        .required("Monthly Expenses is required")
        .positive("Monthly Expenses must be greater than 0")
        .typeError("Monthly Expenses must be greater than 0"),
    largeExposureIndicator: Yup.boolean()
        .notRequired()
        .nullable()
        .test("is-valid", "Large Exposure Indicator is a required field", function (value) {
            return value === true || value === false;
        }),
});

const CustomerCorporate = ({ customerId, cocreeCustomer, dictionaryEnums }: CustomerCorporateProps): ReactElement => {
    const BUSINESS_TYPE = Object.entries((dictionaryEnums).BusinessType).map(([label, value]) => ({
        label: label,
        value: value
    }));
    const [{ data: createdCustomer, loading: createCustomerLoading }, createCustomer] = useAxios<CustomerData>(
        cocreeCustomer.data.id ? "/command/UpdateCocreeCustomer" : "/command/CreateCocreeCustomer",
        {
            manual: true,
        }
    );
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();

    const hasAccess = commandAccessService.canExecute("CreateCocreeCustomer") || commandAccessService.canExecute("UpdateCocreeCustomer");

  const formInitValues = {
            businessType: cocreeCustomer.data?.businessType ?? "",
            termOfExistence: cocreeCustomer.data?.termOfExistence ?? "",
            grossAnnualIncome: cocreeCustomer.data?.grossAnnualIncome ?? 0,
            netAnnualIncome: cocreeCustomer.data?.netAnnualIncome ?? 0,
            monthlyExpenses: cocreeCustomer.data?.monthlyExpenses ?? 0,
            largeExposureIndicator: cocreeCustomer.data?.id ? cocreeCustomer.data?.largeExposureIndicator : null,
        }

    return (
        <>
            {(
                <NxFormik<CustomerData>
                    initialValues={formInitValues}
                    validationSchema={CustomerCorporateSchema}
                    onSubmit={async (values): Promise<void> => {
                        try {
                            await createCustomer({
                                method: 'POST',
                                data: {
                                    id: cocreeCustomer.data.id ?? null,
                                    customerId: customerId,
                                    customerType: 'CORPORATE',
                                    ...values
                                }
                            })
                            history.goBack()
                        } catch(err) {
                            console.error(err)
                        }
                    }}
                >
                    {({ isValid, values, isSubmitting, submitForm, handleChange, setFieldValue }): ReactElement => {
                        return (
                            <NxForm>
                                <NxStack>
                                    <NxFormikSelect
                                        disabled={!hasAccess}
                                        name="businessType"
                                        label="Business Type"
                                        options={BUSINESS_TYPE}
                                    />

                                    <NxFormikDatePicker disabled={!hasAccess} name="termOfExistence" label="Term of Existence"/>

                                    <NxFormikCashInput disabled={!hasAccess} name="grossAnnualIncome" label="Gross Annual Income" />

                                    <NxFormikCashInput disabled={!hasAccess} name="netAnnualIncome" label="Net Annual Income" />

                                    <NxFormikCashInput disabled={!hasAccess} name="monthlyExpenses" label="Monthly Expenses" />

                                    <NxFormikSelect
                                        disabled={!hasAccess}
                                        name="largeExposureIndicator"
                                        label="Large Exposure Indicator"
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                            { label: "", value: null },
                                        ]}
                                    />

                                    <NxRow>
                                        <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                                            Cancel
                                        </NxButton>
                                      {
                                        hasAccess &&
                                        <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                            Save
                                        </NxButton>
                                      }
                                    </NxRow>

                                    <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    submitForm();
                                                    setShowSavePopup(false);
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>

                                    <NxPopup
                                        header="Confirm"
                                        open={showCancelPopup}
                                        description="Discard changes and go to previous screen?"
                                    >
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    setShowCancelPopup(false);
                                                    history.goBack();
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>
                                </NxStack>
                            </NxForm>
                        );
                    }}
                </NxFormik>
            )}
        </>
    );
};

export default CustomerCorporate;
